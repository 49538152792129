import { useRef } from 'react';
import {
    ApolloProvider,
    ApolloClient,
    InMemoryCache,
    HttpLink,
  } from '@apollo/client';
  import { setContext } from '@apollo/link-context';
  import { useAuth0 } from '@auth0/auth0-react';
  
  const ApolloProviderWithAuth0 = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();
  
    const httpLink = new HttpLink({
      uri: "https://trapped-portal.hasura.app/v1/graphql",
    });
  
    const authLink = setContext(async (_, { headers, ...rest }) => {
      let token;
      try {
        token = await getAccessTokenSilently();
      } catch (error) {
        console.log(error);
      }
  
      if (!token) return { headers, ...rest };
  
      return {
        ...rest,
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    });
  
    const client = useRef();
  
    if (!client.current) {
      client.current = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
      });
    }
  
    return (
      <ApolloProvider client={client.current}>
        {children}
      </ApolloProvider>
    );
  };
  
  export { ApolloProviderWithAuth0 };