import React, { Component } from 'react';
import './trezorpin.css';

class TrezorPinpad extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pin: []
    }
    this.addNumber = this.addNumber.bind(this);
    this.clearPin = this.clearPin.bind(this);
    this.submitPin = this.submitPin.bind(this);
  }

  render() {
    return (<form action="" method="" className="trezorpin" autoComplete="off">
      <input className="trezorpin-result" type="password" value={this.state.pin.join('')} disabled />
      <input type="button" className="trezorpin-button" name="7" value="7" onClick={this.addNumber} />
      <input type="button" className="trezorpin-button" name="8" value="8" onClick={this.addNumber} />
      <input type="button" className="trezorpin-button" name="9" value="9" onClick={this.addNumber} />
      <br/>
      <input type="button" className="trezorpin-button" name="4" value="4" onClick={this.addNumber} />
      <input type="button" className="trezorpin-button" name="5" value="5" onClick={this.addNumber} />
      <input type="button" className="trezorpin-button" name="6" value="6" onClick={this.addNumber} />
      <br/>
      <input type="button" className="trezorpin-button" name="1" value="1" onClick={this.addNumber} />
      <input type="button" className="trezorpin-button" name="2" value="2" onClick={this.addNumber} />
      <input type="button" className="trezorpin-button" name="3" value="3" onClick={this.addNumber} />
      <br/>
      <input type="button" className="trezorpin-button clear" value="clear" onClick={this.clearPin} />
      <input type="button" className="trezorpin-button empty" />
      <input type="button" className="trezorpin-button enter" value="enter" onClick={this.submitPin} />
    </form>);
  }

  addNumber(event) {
   this.setState({ pin: this.state.pin.concat([ event.target.name ]) });
  }

  clearPin(event) {
    this.setState({ pin: [] });
  }

  submitPin(event) {
    this.props.onChange(this.state.pin.join(''));
  }
}

export default TrezorPinpad;
