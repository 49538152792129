import { Auth0Provider } from '@auth0/auth0-react'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import React, { Component } from 'react'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import Login from './Login';
import ThemeSelector from './ThemeSelector'
import ThemeHints from './ThemeHints'
import { ApolloProviderWithAuth0 } from './ApolloProviderWithAuth0';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/themes/:id",
    element: <ThemeSelector />,
  },
  {
    path: "/theme/:id",
    element: <ThemeHints />,
  },
]);
const domain = "login.trapped.com";
const clientId = "jA1B6ojXyi8YM0sNhzjZnbATPQgrDptj";

const client = new ApolloClient({
  uri: '"https://trapped-portal.hasura.app/v1/graphql"',
  cache: new InMemoryCache(),
});

class Main extends Component {

  render() {
    return (
      <React.StrictMode>
        <Auth0Provider
          domain={domain}
          clientId={clientId}
          authorizationParams={{
            audience: "https://trapped-admin.hasura.app/v1/graphql",
            redirect_uri: window.location.origin
          }}
        >
          <ApolloProviderWithAuth0>
            <RouterProvider router={router} />
          </ApolloProviderWithAuth0>
        </Auth0Provider>
      </React.StrictMode>
    );
  }
}

export default Main;