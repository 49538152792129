import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          "You have used up all your hints! Now you are officially on your own, good luck team!": "You have used up all your hints! Now you are officially on your own, good luck team!",
          "hintsRemaining": "You have {{count}} / 2 Hint(s) Remaining",
          "Are you sure you want to use this hint?": "Are you sure you want to use this hint?",
          "Yes": "Yes",
          "No": "No",
          "Hint": "Hint",
          "Close": "Close",
          "Confirm?": "Confirm?",
          "confirmDependentSymbolPart1": "Warning* You will need to solve ",
          "confirmDependentSymbolPart2": " prior to attempting this. Are you sure to continue?"
        }
      },
      fr: {
        translations: {
          "You have used up all your hints! Now you are officially on your own, good luck team!":
          "Vous avez maintenant utilisé tous vos indices. Maintenant, vous êtes officiellement seul. Bonne chance à l'équipe!",
          "hintsRemaining": "Vous avez {{count}} / 2 indice(s) restant(s)",
          "Are you sure you want to use this hint?": "Êtes-vous sûr de vouloir utiliser cet indice?",
          "Yes": "Oui",
          "No": "Non",
          "Confirm": "Confirmer",
          "Close": "Fermer",
          "Hint": "Allusion",
          "confirmDependentSymbolPart1": "*Avertissement* Vous devrez résoudre ",
          "confirmDependentSymbolPart2": " avant de tenter ceci. Êtes-vous sûr de vouloir continuer?"
        }
      }
    },
    fallbackLng: "en",
    debug: true,
    lng: "en",

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    load: 'languagesOnly',

    keySeparator: false, // we use content as keys
    whitelist: ['en', 'fr'],
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
