import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'
import TrappedLogo from '../logo';
import { useAuth0 } from "@auth0/auth0-react";
import LocationSelector from '../LocationSelector'
import { useNavigate } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

const GET_LOCATIONS = gql`
  query ($locationIds: [Int!]) {
    locations(where: {id: {_in: $locationIds}}) {
      id,	
      location_name
    }
  }
`;

export default function LoginForm() {
  const { loginWithRedirect, user, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate()
  const locationIds = user?.["https://portal.trapped.com/jwt/claims"]?.["allowed-org-ids"];
  if (isAuthenticated && locationIds.length == 1) {
    navigate(`/themes/${locationIds[0]}`);
  }

  const { loading, error, data } = useQuery(GET_LOCATIONS, {variables: {locationIds}});

  const onLocationChange = (locationId) => {
    navigate(`/themes/${locationId}`);
  }
  return (
    <Form className="login100-form validate-form">
      <span className="login100-form-logo">
        <TrappedLogo width="70" />
      </span>

      <span className="login100-form-title p-b-34 p-t-27">
        Welcome Back
      </span>

      {!isAuthenticated &&
        <div className="container-login100-form-btn">
          <button className="login100-form-btn" onClick={() => loginWithRedirect()}>
            Login
          </button>
        </div>
      }
      {
        isAuthenticated &&
        <LocationSelector locations={data?.locations} onLocationChange={onLocationChange}/>
      }
    </Form>
  );

}