import * as React from "react"

const TrappedLogo = (props) => (
  <svg
    width={142.848}
    height={146.526}
    viewBox="0 0 37.795 38.768"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <clipPath clipPathUnits="userSpaceOnUse" id="c">
        <path
          d="M196.343 388.278v44.162h-23.184l-11.956 11.297h44.995l1.44-40.696 1.441 40.696h44.996l-11.956-11.297h-23.184v-44.162l-11.296-11.534z"
          clipRule="evenodd"
        />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="e">
        <path
          d="m197.385 388.704 10.254-10.471 10.254 10.47v44.78h25.329l9.75 9.212H210.12l-2.031-26.978.592-12.698-.022.246h-2.038l-.023-.246.593 12.698-2.031 26.978h-42.853l9.75-9.213h25.329z"
          clipRule="evenodd"
        />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="g">
        <path
          d="m207.639 464.18-15.807-16.338h31.613zm34.679-35.846H228.36l18.48-19.1-39.201-40.52-39.2 40.52 18.479 19.1H172.96l-18.48-19.1 53.159-54.947 53.158 54.947z"
          clipRule="evenodd"
        />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="i">
        <path
          d="m209.192 411.148 1.435-6.998c.237-1.154.55-3.019-1.228-3.019h-3.516c-1.779 0-1.465 1.865-1.228 3.02l1.435 6.994a3.29 3.29 0 1 0 3.102.003"
          clipRule="evenodd"
        />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="k">
        <path
          d="M196.343 388.278v44.162h-23.184l-11.956 11.297h44.995l1.44-40.696 1.441 40.696h44.996l-11.956-11.297h-23.184v-44.162l-11.296-11.534z"
          clipRule="evenodd"
        />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="m">
        <path
          d="m197.385 388.704 10.254-10.471 10.254 10.47v44.78h25.329l9.75 9.212H210.12l-2.031-26.978.592-12.698-.022.246h-2.038l-.023-.246.593 12.698-2.031 26.978h-42.853l9.75-9.213h25.329z"
          clipRule="evenodd"
        />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="o">
        <path
          d="m207.639 464.18-15.807-16.338h31.613zm34.679-35.846H228.36l18.48-19.1-39.201-40.52-39.2 40.52 18.479 19.1H172.96l-18.48-19.1 53.159-54.947 53.158 54.947z"
          clipRule="evenodd"
        />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="q">
        <path
          d="m209.192 411.148 1.435-6.998c.237-1.154.55-3.019-1.228-3.019h-3.516c-1.779 0-1.465 1.865-1.228 3.02l1.435 6.994a3.29 3.29 0 1 0 3.102.003"
          clipRule="evenodd"
        />
      </clipPath>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 76.55244 -92.8715 0 254.075 367.184)"
        spreadMethod="pad"
        id="a"
      >
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#000",
          }}
          offset={0}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#00a650",
          }}
          offset={0.56}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#00a650",
          }}
          offset={1}
        />
      </linearGradient>
      <linearGradient
        x1={0.09}
        y1={0}
        x2={0.91}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-56.9051 98.36867 -102.10438 -59.06616 287.144 390.813)"
        spreadMethod="pad"
        id="b"
      >
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#000",
          }}
          offset={0}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#00a650",
          }}
          offset={0.73}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#00a650",
          }}
          offset={1}
        />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="l"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 76.55244 -92.8715 0 254.075 367.184)"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        spreadMethod="pad"
      />
      <linearGradient
        xlinkHref="#b"
        id="n"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-56.9051 98.36867 -102.10438 -59.06616 287.144 390.813)"
        x1={0.09}
        y1={0}
        x2={0.91}
        y2={0}
        spreadMethod="pad"
      />
      <linearGradient
        xlinkHref="#a"
        id="d"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 76.55244 -92.8715 0 254.075 367.184)"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        spreadMethod="pad"
      />
      <linearGradient
        xlinkHref="#b"
        id="f"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-56.9051 98.36867 -102.10438 -59.06616 287.144 390.813)"
        x1={0.09}
        y1={0}
        x2={0.91}
        y2={0}
        spreadMethod="pad"
      />
      <radialGradient
        fx={0}
        fy={0}
        cx={0}
        cy={0}
        r={0.62}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(207.639 392.75) scale(109.89439)"
        spreadMethod="pad"
        id="h"
      >
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#656263",
          }}
          offset={0}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#000",
          }}
          offset={0.61}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#000",
          }}
          offset={1}
        />
      </radialGradient>
      <radialGradient
        fx={0}
        fy={0}
        cx={0}
        cy={0}
        r={0.81}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(208.034 404.696) scale(16.2051)"
        spreadMethod="pad"
        id="j"
      >
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#000",
          }}
          offset={0}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#00a650",
          }}
          offset={0.52}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#000",
          }}
          offset={1}
        />
      </radialGradient>
      <radialGradient
        fx={0}
        fy={0}
        cx={0}
        cy={0}
        r={0.62}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(207.639 392.75) scale(109.89439)"
        spreadMethod="pad"
        id="p"
      >
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#656263",
          }}
          offset={0}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#000",
          }}
          offset={0.61}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#000",
          }}
          offset={1}
        />
      </radialGradient>
      <radialGradient
        fx={0}
        fy={0}
        cx={0}
        cy={0}
        r={0.81}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(208.034 404.696) scale(16.2051)"
        spreadMethod="pad"
        id="r"
      >
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#000",
          }}
          offset={0}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#00a650",
          }}
          offset={0.52}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#000",
          }}
          offset={1}
        />
      </radialGradient>
    </defs>
    <g
      clipPath="url(#c)"
      transform="matrix(.35278 0 0 -.35278 -54.208 163.753)"
    >
      <path
        d="M196.343 388.278v44.162h-23.184l-11.956 11.297h44.995l1.44-40.696 1.441 40.696h44.996l-11.956-11.297h-23.184v-44.162l-11.296-11.534z"
        style={{
          fill: "url(#d)",
          fillRule: "evenodd",
          stroke: "none",
        }}
      />
    </g>
    <g
      clipPath="url(#e)"
      transform="matrix(.35278 0 0 -.35278 -54.208 163.753)"
    >
      <path
        d="m197.385 388.704 10.254-10.471 10.254 10.47v44.78h25.329l9.75 9.212H210.12l-2.031-26.978.592-12.698-.022.246h-2.038l-.023-.246.593 12.698-2.031 26.978h-42.853l9.75-9.213h25.329z"
        style={{
          fill: "url(#f)",
          fillRule: "evenodd",
          stroke: "none",
        }}
      />
    </g>
    <g
      clipPath="url(#g)"
      transform="matrix(.35278 0 0 -.35278 -54.208 163.753)"
    >
      <path
        d="m207.639 464.18-15.807-16.338h31.613zm34.679-35.846H228.36l18.48-19.1-39.201-40.52-39.2 40.52 18.479 19.1H172.96l-18.48-19.1 53.159-54.947 53.158 54.947z"
        style={{
          fill: "url(#h)",
          fillRule: "evenodd",
          stroke: "none",
        }}
      />
    </g>
    <g
      clipPath="url(#i)"
      transform="matrix(.35278 0 0 -.35278 -54.208 163.753)"
    >
      <path
        d="m209.192 411.148 1.435-6.998c.237-1.154.55-3.019-1.228-3.019h-3.516c-1.779 0-1.465 1.865-1.228 3.02l1.435 6.994a3.29 3.29 0 1 0 3.102.003"
        style={{
          fill: "url(#j)",
          fillRule: "evenodd",
          stroke: "none",
        }}
      />
    </g>
    <path
      d="m100.91 123.532.546 2.662c.074.363.167.775-.196.775h-1.24c-.364 0-.271-.412-.197-.775l.546-2.662a.919.919 0 1 1 .54 0"
      style={{
        fill: "#000",
        fillOpacity: 1,
        fillRule: "evenodd",
        stroke: "none",
        strokeWidth: 0.352778,
      }}
      transform="translate(-81.597 -104.968)"
    />
    <g
      clipPath="url(#k)"
      transform="matrix(.35278 0 0 -.35278 -54.497 163.753)"
    >
      <path
        d="M196.343 388.278v44.162h-23.184l-11.956 11.297h44.995l1.44-40.696 1.441 40.696h44.996l-11.956-11.297h-23.184v-44.162l-11.296-11.534z"
        style={{
          fill: "url(#l)",
          fillRule: "evenodd",
          stroke: "none",
        }}
      />
    </g>
    <g
      clipPath="url(#m)"
      transform="matrix(.35278 0 0 -.35278 -54.497 163.753)"
    >
      <path
        d="m197.385 388.704 10.254-10.471 10.254 10.47v44.78h25.329l9.75 9.212H210.12l-2.031-26.978.592-12.698-.022.246h-2.038l-.023-.246.593 12.698-2.031 26.978h-42.853l9.75-9.213h25.329z"
        style={{
          fill: "url(#n)",
          fillRule: "evenodd",
          stroke: "none",
        }}
      />
    </g>
    <g
      clipPath="url(#o)"
      transform="matrix(.35278 0 0 -.35278 -54.497 163.753)"
    >
      <path
        d="m207.639 464.18-15.807-16.338h31.613zm34.679-35.846H228.36l18.48-19.1-39.201-40.52-39.2 40.52 18.479 19.1H172.96l-18.48-19.1 53.159-54.947 53.158 54.947z"
        style={{
          fill: "url(#p)",
          fillRule: "evenodd",
          stroke: "none",
        }}
      />
    </g>
    <g
      clipPath="url(#q)"
      transform="matrix(.35278 0 0 -.35278 -54.497 163.753)"
    >
      <path
        d="m209.192 411.148 1.435-6.998c.237-1.154.55-3.019-1.228-3.019h-3.516c-1.779 0-1.465 1.865-1.228 3.02l1.435 6.994a3.29 3.29 0 1 0 3.102.003"
        style={{
          fill: "url(#r)",
          fillRule: "evenodd",
          stroke: "none",
        }}
      />
    </g>
    <path
      d="m100.62 123.532.546 2.662c.075.363.168.775-.195.775H99.73c-.363 0-.27-.412-.196-.775l.546-2.662a.919.919 0 1 1 .54 0"
      style={{
        fill: "#000",
        fillOpacity: 1,
        fillRule: "evenodd",
        stroke: "none",
        strokeWidth: 0.352778,
      }}
      transform="translate(-81.597 -104.968)"
    />
  </svg>
)

export default TrappedLogo
