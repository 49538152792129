import Modal from 'react-bootstrap/Modal'
import TrezorPinpad from '../Pin'

export default function AdminModal({show, onChange, close}) {

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>PIN</Modal.Title>
      </Modal.Header>
      <Modal.Body><TrezorPinpad onChange={onChange} /></Modal.Body>
    </Modal>      
  );
}
