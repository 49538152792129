import React from 'react';
import LoginForm from './LoginForm'


export default function Login({ props }) {
  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100">
          <LoginForm />
        </div>
      </div>
    </div>
  );
}