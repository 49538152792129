import ListGroup from 'react-bootstrap/ListGroup'
import Container from 'react-bootstrap/Container'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { Link, useNavigate, useParams } from "react-router-dom";
import "./ThemeSelector.css"
import i18n from "../i18n"
import { useQuery, gql } from '@apollo/client';
import { useAuth0 } from "@auth0/auth0-react";

const GET_THEMES = gql`
query ($locationId: Int!) {
  themes(where: {location_id: {_eq: $locationId}, retired: {_eq: false}, hints_aggregate: {count: {predicate: {_gt: 0}, filter: {published: {_eq: true}}}}}) {
    id
    global_theme {
      name
      cover_url
    }
  }
}
`;

export default function ThemeSelector() {

  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  if(!isAuthenticated) {
    navigate(`/`);
  }
  let { id } = useParams();

  const { loading, error, data } = useQuery(GET_THEMES, {variables: {locationId: id}});

  function languageChange(value) {
    i18n.changeLanguage(value)
  }

  const items = data?.themes?.map((item) =>
    <ListGroup.Item key={item.global_theme.name}><img className="themeImage" src={item.global_theme.cover_url} width="200" /><Link className="themePicker" to={`/theme/${item.id}`} style={{textDecoration: "none"}}>{item.global_theme.name}</Link></ListGroup.Item>
  )
  // const languageSelector = data?.hasFrench || false ? (<ToggleButtonGroup className="language" type="radio" defaultValue={i18n.language} name="language" onChange={languageChange}>
  //           <ToggleButton value={"en"}>English</ToggleButton>
  //           <ToggleButton value={"fr"}>French</ToggleButton>
  //         </ToggleButtonGroup>) : ""
  return (
    <Container>
      <h2>
      Themes
      {/* {languageSelector} */}
      </h2>

      <ListGroup>
        {items}
      </ListGroup>
    </Container>
  );
}