import Modal from 'react-bootstrap/Modal'
import React, { Component, useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button'
import { useTranslation, Trans } from "react-i18next";

const regex = /\{\{(\d+)\}\}/g;
export default function ConfirmationModal({dependsOn, show, onConfirm, close}) {

  var symbols = null;
  if(dependsOn && dependsOn.hint) {
    symbols = dependsOn.hint
      .map(x => x.step)
      .reduce((r, x) => {
        r.push((<img key={`confirm-${x}`} src={`https://s3.amazonaws.com/trapped-hint-system/symbols/${x}.png`} width="30" height="30"/>), (<span> & </span>))
        return r
      }, [])
      symbols.pop()
  }
  let dependsOnCustom;
  if(dependsOn?.custom) {
    dependsOnCustom = dependsOn.custom;
    let replacement;
    do {
      replacement = regex.exec(dependsOnCustom);
      if (replacement) {
        const [match, icon] = replacement;
        dependsOnCustom = dependsOnCustom.replace(match, `<img src="https://s3.amazonaws.com/trapped-hint-system/symbols/${icon}.png" width="30" height="30" />`);
      }
    } while (replacement)
  
  }
  var body = !dependsOn ? (<Modal.Body></Modal.Body>) :
    dependsOn.hint ? (<Modal.Body><Trans i18nKey="confirmDependentSymbolPart1"></Trans>{symbols}<Trans i18nKey="confirmDependentSymbolPart2"></Trans></Modal.Body>)
      : (<Modal.Body className="dependsWarning" dangerouslySetInnerHTML={{ __html: dependsOnCustom }}></Modal.Body>)
  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title><Trans>Confirm</Trans>?</Modal.Title>
      </Modal.Header>
      {body}
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          <Trans>Close</Trans>
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          <Trans>Confirm</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
