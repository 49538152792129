import React, { Component } from 'react';
import "./i18n";
import Main from './Main';

export default function App() {
  return (
    <div>
      <Main />
    </div>
  );
}
