import Modal from 'react-bootstrap/Modal'
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import { useTranslation, Trans } from "react-i18next";

export default function DoubleConfirmationModal({show, dependsOn, close, onConfirm}) {

    return (
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title><Trans>Confirm</Trans>?</Modal.Title>
        </Modal.Header>
          <Modal.Body><Trans>Are you sure you want to use this hint?</Trans></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            <Trans>No</Trans>
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            <Trans>Yes</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    );
}
