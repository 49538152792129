import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useTranslation, Trans } from "react-i18next";
import "./Hints.css";

export default function HintModal({ show, step, close }) {

  let hint = step?.hint ? step.hint : "";

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title><Trans>Hint</Trans></Modal.Title>
      </Modal.Header>
      <Modal.Body className='hint' dangerouslySetInnerHTML={{ __html: hint }}></Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={close}>
          <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
