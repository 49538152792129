import React, { Component, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import HintModal from "./HintModal"
import ConfirmationModal from "./ConfirmationModal"
import DoubleConfirmationModal from "./DoubleConfirmationModal"
import AdminModal from "./AdminModal"
import * as Hammer from 'hammerjs';
import { Trans } from "react-i18next";
import i18n from "../i18n";
import './Hints.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client';
import { useAuth0 } from "@auth0/auth0-react";

const GET_HINTS = gql`
query ($themeId: Int!) {
  hints(where: {published: {_eq: true}, theme_id: {_eq: $themeId}}) {
    data
  }
}
`;

export default function ThemeHints() {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  if(!isAuthenticated) {
    navigate(`/`);
  }

  let { id } = useParams();
  const location = useLocation();

  const [state, setState] = useState({
    show: false,
    doubleConfirmShow: false,
    confirmShow: false,
    currentStep: undefined,
    adminShow: false,
    openedHints: new Set(),
    confirmLambda: function () { }
  })

  const { loading, error, data } = useQuery(GET_HINTS, { variables: { themeId: id } });

  const hints = data?.hints?.[0].data;

  function handleClose() {
    setState({ ...state, show: false });
  }

  function handleConfirmClose() {
    setState({ ...state, confirmShow: false });
  }

  function handleDoubleConfirmClose() {
    setState({ ...state, doubleConfirmShow: false });
  }

  function handleAdminClose() {
    setState({ ...state, adminShow: false });
  }

  function handleValidateAdmin(e) {
    if (e === "2879") {
      navigate(-1);
    }
  }

  function handleShow(hint) {
    return () => {
      if (state.openedHints.size >= hints.numberOfHints && !state.openedHints.has(hint.step)) {
        return;
      }
      if (state.openedHints.has(hint.step)) {
        tryShow(hint);
        return;
      }
      setState(
        {
          ...state,
          doubleConfirmShow: true,
          doubleConfirmLambda: () => {
            handleDoubleConfirmClose();
            tryShow(hint);
          }
        }
      );
    }
  }

  function tryShow(hint) {
    if ((hint?.dependsOn?.length > 0 || hint.customDependsMessage) && !state.openedHints.has(hint.step)) {
      var hint0 = undefined;
      if (hint?.dependsOn?.length > 0) {
        hint0 = hints.steps.filter(x => hint.dependsOn.includes(x.step));
      }
      setState({
        ...state,
        confirmShow: true,
        confirmShowDepends: { hint: hint0, custom: hint.customDependsMessage },
        confirmLambda: () => {
          handleConfirmClose();
          showHint(hint);
        }
      })
      return;
    }
    showHint(hint)
  }

  function showHint(hint) {
    setState({ ...state, show: true, currentStep: hint, openedHints: state.openedHints.add(hint.step) });
  }


  var mc = new Hammer.Manager(window);
  var press = new Hammer.Press({ time: 3000 })
  mc.add(press);

  mc.on("press", (ev) => {
    setState({ ...state, adminShow: true });
  });

  // var url = i18n.language == "en" ? location.state.theme.url : location.state.theme.frUrl

  if (!data?.hints?.[0]?.data) {
    return (<></>);
  }

  const buttons = data?.hints?.[0]?.data.steps?.map((hint) => {
    return (
      <Button key={hint.name} onClick={handleShow(hint)}><img key={`img-${hint.name}`} src={`https://s3.amazonaws.com/trapped-hint-system/symbols/${hint.step}.png`} width="40" height="40" /></Button>
    )
  });

  var rows = [];
  for (var i = 0; i < buttons.length; i++) {
    const x = (<Row key={`hint-${i}`}>
      <Col>{buttons[i]}</Col>
    </Row>)
    rows.push(x);
  }

  const alert =
    state?.openedHints?.size >= hints.numberOfHints ? (<Alert variant="warning">
      <Trans>You have used up all your hints! Now you are officially on your own, good luck team!</Trans>
    </Alert>) : (
      <Alert variant="primary">
        <Trans i18nKey="hintsRemaining" values={{ count: hints.numberOfHints - state?.openedHints?.size }}></Trans>
      </Alert>);

  return (
    <Container className="step_selector">
      <h2 align="center">{hints.name}</h2>
      {alert}
      {rows}
      <HintModal show={state.show} step={state.currentStep} close={handleClose}></HintModal>
      <DoubleConfirmationModal show={state.doubleConfirmShow} onConfirm={state.doubleConfirmLambda} close={handleDoubleConfirmClose}></DoubleConfirmationModal>
      <ConfirmationModal show={state.confirmShow} onConfirm={state.confirmLambda} close={handleConfirmClose} dependsOn={state.confirmShowDepends}></ConfirmationModal>
      <AdminModal show={state.adminShow} close={handleAdminClose} onChange={handleValidateAdmin} />
    </Container>
  );

}
