import { useState } from "react";
export default function LocationSelector({ locations, onLocationChange }) {
    const [selected, setSelected] = useState(0)
    const onChange = (event) => {
        setSelected(event.target.value);
    };

    const onClick = () => {
        if (selected !== 0) {
            onLocationChange(selected);
        }
    };

    return (
        <div>
            <select style={{ width: "100%", height: 50, textAlignLast: "center" }} onChange={onChange}>
                <option key="select" value="0">Select Location</option>
                {locations?.map((location) => (<option value={location.id} key={location.id}>{location.location_name}</option>))}
            </select>

            <div className="container-login100-form-btn" style={{ marginTop: 25 }}>
                <button className="login100-form-btn" onClick={onClick} disabled={selected === 0} >
                    GO
                </button>
            </div>
        </div>
    );
}